// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/backgrounds/bg-homepage-spaceman-center.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".desktop-content[data-v-5c134f2d] {\n  /*background-image: url(~@/assets/images/backgrounds/bg-homepage.svg);*/\n  background-size: cover;\n  background-position: 100% bottom;\n}\n.desktop-content .banner-content-container[data-v-5c134f2d] {\n  height: 800px;\n}\n.mobile-content[data-v-5c134f2d] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: bottom center;\n  background-size: 88rem;\n  padding: 0 0 30rem 0;\n}\n.extension-btns[data-v-5c134f2d] {\n  color: white;\n  background: rgba(0, 0, 0, 0.12);\n}\n.browser-icons[data-v-5c134f2d] {\n  width: 25px;\n  height: 25px;\n}\n.app-icons[data-v-5c134f2d] {\n  width: 55px;\n  max-height: 55px;\n}", ""]);
// Exports
module.exports = exports;
