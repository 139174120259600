var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-component--landing" }, [
    _c(
      "div",
      { staticClass: "desktop-content d-none d-lg-block expandHeader" },
      [
        _c(
          "v-container",
          {
            staticClass:
              "banner-content-container d-flex align-center justify-space-between",
          },
          [
            _c(
              "v-card",
              {
                attrs: {
                  color: "transparent",
                  flat: "",
                  tile: "",
                  "max-width": "430px",
                },
              },
              [
                _c("div", { staticClass: "white--text mew-title" }, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("lokalise")(
                            _vm.$t("home.tagline1"),
                            "home.tagline1"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("lokalise")(
                            _vm.$t("home.tagline2"),
                            "home.tagline2"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "white--text mt-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("lokalise")(
                          _vm.$t("home.subheading"),
                          "home.subheading"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-9 d-flex" },
                  [
                    _c("mew-button", {
                      staticClass: "mr-3 HomeCreateWallet",
                      attrs: {
                        "has-full-width": false,
                        title: "Create a new wallet",
                        "btn-size": "xlarge",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: _vm.ROUTES_HOME.CREATE_WALLET.NAME,
                            params: {},
                          })
                        },
                      },
                    }),
                    _c("mew-button", {
                      staticClass: "HomeAccessWallet",
                      attrs: {
                        "has-full-width": false,
                        "btn-style": "outline",
                        title: "Access my wallet",
                        "btn-size": "xlarge",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME,
                            params: {},
                          })
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mobile-content d-block d-lg-none expandHeader pt-5" },
      [
        _c(
          "v-container",
          [
            _c(
              "v-card",
              {
                staticClass: "mx-auto",
                attrs: {
                  color: "transparent",
                  flat: "",
                  tile: "",
                  "max-width": "400px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "white--text font-weight-bold mb-4",
                    staticStyle: {
                      "font-size": "3rem",
                      "line-height": "3.3rem",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("lokalise")(
                              _vm.$t("home.tagline1"),
                              "home.tagline2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("lokalise")(
                              _vm.$t("home.tagline2"),
                              "home.tagline2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c("p", { staticClass: "white--text mt-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("lokalise")(
                          _vm.$t("home.subheading"),
                          "home.subheading"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-12" },
                  [
                    _c("mew-button", {
                      staticClass: "mb-3 width--full",
                      attrs: {
                        "has-full-width": false,
                        title: "Create a new wallet",
                        "btn-size": "xlarge",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: _vm.ROUTES_HOME.CREATE_WALLET.NAME,
                            params: {},
                          })
                        },
                      },
                    }),
                    _c("mew-button", {
                      staticClass: "width--full",
                      attrs: {
                        "has-full-width": false,
                        "btn-style": "outline",
                        title: "Access my wallet",
                        "btn-size": "xlarge",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME,
                            params: {},
                          })
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }